import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: #2B5466;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-size: cover;
  background-position: center;
`;

export const FULLContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: url("/config/images/bg.jpg");;
  background-size: cover;
  background-position: center;
  @media (max-width: 565px) {
    background-image: url("/config/images/bgm.jpg");
  }
`;

export const SupplyContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  width: 100%;
  margin: auto;
  background-color: #b3ffff1a;
  border-radius: 5px;
  border: 2px solid #ffffffa8;
  -webkit-box-shadow: 0px 0px 12px 10px #357070; 
  box-shadow: 0px 0px 12px 10px #357070;;
  align-content: center;
`;

export const SupplyPrice = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  width: 100%;
  margin: auto;
  background-color: #b3ffff1a;
  border-radius: 5px;
  border: 2px solid #ffffffa8;
  -webkit-box-shadow: 0px 0px 12px 10px #357070; 
  box-shadow: 0px 0px 12px 10px #357070;;
  align-content: center;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const SupplyTotal = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  width: 100%;
  margin: auto;
  background-color: #b3ffff1a;
  border-radius: 5px;
  border: 2px solid #ffffffa8;
  -webkit-box-shadow: 0px 0px 12px 10px #357070; 
  box-shadow: 0px 0px 12px 10px #357070;;
  align-content: center;
  margin-left: 10%;
  @media (min-width: 1800px) {
    margin-top: 75px;
    margin-bottom: 70px;
  }

`;

export const SupplyDOGS = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  width: 100%;
  margin: auto;
  background-color: #b3ffff1a;
  border-radius: 5px;
  border: 2px solid #ffffffa8;
  -webkit-box-shadow: 0px 0px 12px 10px #357070; 
  box-shadow: 0px 0px 12px 10px #357070;;
  align-content: center;
  margin-left: 10%;
  margin-top: 0px;
`;

export const TextTitle = styled.p`
  font-family: 'Upheaval';
  color: var(--primary-text);
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 8px;
  line-height: 1.6;
`;

export const TextSub = styled.p`
  font-family: 'Upheaval';
  color: var(--primary-text);
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 8px;
  line-height: 1.6;
  @media (max-width: 565px) {
    font-size: 20px;
    letter-spacing: 2px;
  }
`;

export const Textright = styled.p`
  font-family: 'AZ';
  color: var(--primary-text);
  font-size: 6px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 1;
  @media (max-width: 565px) {
    font-size: 20px;
    letter-spacing: 2px;
  }
`;

export const TextPrice = styled.p`
  font-family: 'Upheaval';
  color: var(--primary-text);
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 8px;
  line-height: 1.6;
  width: 200px;
  text-align: center;
  @media (max-width: 565px) {
    font-size: 20px;
    letter-spacing: 2px;
    padding-left: 85px;
  }
`;

export const TextSupply = styled.p`
  font-family: 'Upheaval';
  color: var(--primary-text);
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 1.6;
  width: 230px;
  text-align: center;
  @media (max-width: 565px) {
    font-size: 0.75rem;
    letter-spacing: 2px;
    padding-left: 50px;
  }
`;

export const TextSubTitle = styled.p`
  font-family: 'AZ';
  color: var(--primary-text);
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 60px;
  line-height: 1.6;
`;

export const TextTokens = styled.p`
  color: var(--primary-text);
  font-size: 2rem;
  font-family: "AZ";
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const StyledHR = styled.hr`
  border: 2px solid white;  
  background-color: white;
  border-radius: 2px;
  width: 450px;
  @media (max-width: 565px) {
    width: 350px;
  }
`;

export const MintContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  margin-bottom: -110px;
`;

export const NUMContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  margin-top: 50%;
`;

export const DIV = styled.div`
margin-top: 10%;
margin-left: 10%;
@media (max-width: 565px) {
  margin-top: 0;
  margin-left: 20%;
}
@media (min-width: 1800px) {
  margin-top: 80%;
}
`;

export const DIV2 = styled.div`
margin-top: 10%;
@media (max-width: 565px) {
display: none;
}
`;

export const DIV3 = styled.div`
margin-top: 13%;
margin-bottom: 20%;
@media (max-width: 565px) {
  margin-left: 20%;
  display: none;
}
`;

export const LEFTCT = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: space-evenly;
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  margin-left: 15%;

  @media (min-width: 1800px) {
    margin-left: 22%;
    margin-bottom: 16%;
  }

  @media (max-width: 1300px) {
    margin-left: 55px;
  }

  @media (max-width: 780px) {
    margin-left: -5px;
  }
    
  @media (max-width: 565px) {
    gap: 20px;
    margin-left: -25px;
  }
`;

export const RightCT = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: space-evenly;
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  margin-right: 15%;
  @media (min-width: 1800px) {
    margin-right: 22%;
    justify-content: center;
  }
  @media (max-width: 1300px) {
    margin-right: 75px;
  }

  @media (max-width: 780px) {
    margin-right: -60px;
  }

  @media (max-width: 565px) {
    margin-bottom: 180px;
    margin-right: 15%;
  }
`;


