import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import "rsuite/dist/rsuite.min.css"
import { Notification, toaster } from 'rsuite';
import Web3 from "web3";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  font-family: 'AZ';
  padding: 10px;
  font-size: 24px;
  //border-radius: 50%;
  //border: 10px double white;
  //background-color: transparent;
  border: none;
  background: url(/config/images/circ.png);
  background-position: center;
  background-size: 1000px;
  padding: 10px;
  letter-spacing: 6px;
  font-weight: bold;
  color: red;
  width: 190px;
  height: 200px;
  cursor: pointer;
  //-webkit-box-shadow: 0px 0px 12px 10px #357070; 
   //box-shadow: 0px 0px 12px 10px #357070;
  :hover {
    color: white;
  }
  @media (max-width: 565px) {
    width: 250px;
    height: 175px;
    background-size: 950px;
  }
`;

export const CTNBTN = styled.button`
  font-family: 'AZ';
  padding: 10px;
  font-size: 1rem;
  border: none;
  //border-radius: 50%;
  //border: 10px double white;
  //background-color: transparent;
  background: url(/config/images/circ.png);
  background-position: center;
  background-size: 1000px;
  padding: 10px;
  letter-spacing: 6px;
  font-weight: bold;
  color: red;
  width: 190px;
  height: 200px;
  cursor: pointer;
  //-webkit-box-shadow: 0px 0px 12px 10px #357070; 
  //box-shadow: 0px 0px 12px 10px #357070;
  :hover {
    color: white;
  }
  @media (max-width: 565px) {
    width: 250px;
    height: 175px;
    background-size: 950px;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 50%;
  padding: 10px;
  background: transparent;
  border: 2px solid white;
  font-size: 35px;
  color: #357070;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
-webkit-box-shadow: 0px 0px 12px 10px #357070; 
box-shadow: 0px 0px 12px 10px #357070;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: white;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  margin: auto;
  width: 75%;
  //margin-top: 5%;
  margin-bottom: 15%;
 // border-radius: 45px 2px; 
 // border: 3px solid #e6f8fb;
 // -webkit-box-shadow: -1px 2px 18px 9px #ffff; 
   //box-shadow: -1px 2px 18px 9px #ffff;
   background: url(/config/images/cont.png);
   background-position: center;
   background-size: 1300px;
  @media (min-width: 767px) {
    flex-direction: row;
  }

  @media (max-width: 780px) {
    margin-left: -40px;
   width: 100%;
  }

  @media (max-width: 585px) {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    background: none;
    background-position: none;
    background-size: none;
  }

`;

export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  display: inline;
  width: 100px;
  @media (max-width: 767px) {
    width: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 200px;
  @media (min-width: 900px) {
    width: 200px;
  }
  @media (min-width: 1000px) {
    width: 200px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const WalletBox = styled.div`
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid white;
  background-color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [walletAddress, setAddress] = useState("Not Connected");
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Choose Your Side`);
  const [tokens, settokens] = useState(1);
  const [type, setType] = React.useState('info');
  const [placement, setPlacement] = React.useState('topStart');
  const errmessage = (
    <Notification type={'error'} header={'error'} closable>
     Sorry, something went wrong please try again later.
    </Notification>
  );
  const txmessage = (
    <Notification type={'success'} header={'success'} closable>
     Congrats, Mint Was successfull.
    </Notification>
  );
  const mntmessage = (
    <Notification type={'info'} header={'info'} closable>
     Minting in Progress....
    </Notification>
  );

  const WLmessage = (
    <Notification type={'error'} header={'error'} closable>
     You Are Not Whitelisted
    </Notification>
  );
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WL_SUPPLY: 1,
    DISPLAY_COST: 0,
    WL_Display: 0,
    GAS_LIMIT: 0,
    WEB_LINK: "",
    Presale: false,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  //Merkle
  const { MerkleTree } = require('merkletreejs');
  const keccak256 = require('keccak256');
  let Whitelist = require('./Accounts.json');
  const leafNodes = Whitelist.map(addr => keccak256(addr));
  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true});
  const rootHash = merkleTree.getRoot();
  const claimingAddress = keccak256(blockchain.account);
  const hexProof = merkleTree.getHexProof(claimingAddress);
  console.log("Root Hash: ", '0x' + rootHash.toString('hex'));
//Merkle

  const claimNFTs = () => {
    let cost = CONFIG.DISPLAY_COST * tokens;
    let price = Web3.utils.toWei(cost.toString(), 'ether');
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", price);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.SYMBOL}...`);
    setClaimingNft(true);
    toaster.push(mntmessage, { placement })
    blockchain.smartContract.methods
      .mint(tokens)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: price,
      })
      .once("error", (err) => {
        console.log(err);
        toaster.push(errmessage, { placement })
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        toaster.push(txmessage, { placement })
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };
  
  const WlMint = () => {
    let cost = CONFIG.WL_COST * tokens;
    let price = Web3.utils.toWei(cost.toString(), 'ether');
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", price);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.SYMBOL}...`);
    setClaimingNft(true);
    toaster.push(mntmessage, { placement })
    blockchain.smartContract.methods
      .presalemint(tokens, hexProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: price,
      })
      .once("error", (err) => {
        console.log(err);
        toaster.push(errmessage, { placement })
        setFeedback("Sorry, Something Went Wrong");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        toaster.push(txmessage, { placement })
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };


  const decrementtokens = () => {
    let newtokens = tokens - 1;
    if (newtokens < 1) {
      newtokens = 1;
    }
    settokens(newtokens);
  };

  const incrementtokens = () => {
    let newtokens = tokens + 1;
    if (newtokens > 10) {
      newtokens = 10;
    }
    settokens(newtokens);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setAddress(blockchain.account.substring(0,4) + "..." + blockchain.account.substring(38,42));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.FULLContainer
        flex={1}
        // ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
      >
        <s.SpacerSmall />

        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.LEFTCT>
            <s.DIV>
            <div>
              <StyledImg src="/config/images/logo.png"/>
            </div>
            <div>
            <StyledImg src="/config/images/bars.png" />
            </div>
            </s.DIV>
          <s.SupplyTotal>
            <s.TextSupply>
          Total Dogs Available
          </s.TextSupply>
          <s.SpacerXSmall/>
          {CONFIG.Presale === false ? (
            <>
            <s.TextPrice>
            {Number(data.totalSupply)} ||  {CONFIG.MAX_SUPPLY}
          </s.TextPrice>
            </>
          ) : (
            <>
             <s.TextPrice>
            {Number(data.totalSupply)} ||  {CONFIG.WL_SUPPLY}
          </s.TextPrice>           
            </>
          )}

          </s.SupplyTotal>
          <s.SupplyDOGS>
            <s.TextSupply>
          Good Dogs : 5000
          </s.TextSupply>
          <s.SpacerXSmall/>
          <s.TextSupply>
          BAD Dogs: 5000
          </s.TextSupply>
          </s.SupplyDOGS>
          </s.LEFTCT>
            <s.Container flex={1} jc={"center"} ai={"center"} >

           {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextSub
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder" }}
                >
                  The sale has ended.
                </s.TextSub>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                     <StyledImg src="/config/images/warning.png" />
                    <s.SpacerSmall />
                    <CTNBTN
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </CTNBTN>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                            fontFamily: "AZ",
                            fontSize: "2rem"
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.MintContainer ai={"center"} jc={"center"} fd={"column"}>
                    <StyledImg src="/config/images/warning.png" />
                      { CONFIG.Presale === true ? (
                        <>
                        { hexProof.length > 1 ? (
                          <>
                          <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          WlMint();
                          getData();
                        }}
                      >
                        {claimingNft ? "MINTING..." : "Whitelist MINT"}
                      </StyledButton>
                      </>
                      ) : (
                        <>
                        
                        <s.TextSub
                          style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "coder"  }}
                        >
                          <div style={{display: "none"}}>
                          {toaster.push(WLmessage, { placement })}
                          </div>
                         You're Not Whitelisted
                        </s.TextSub>
                          </>

                      )}
                      </>

                           ) : (
                             <>
                            <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                              getData();
                            }}
                          >
                            {claimingNft ? "MINTING..." : "MINT"}
                          </StyledButton>
                             </>

                            )}
                    </s.MintContainer>
                    <s.NUMContainer ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementtokens();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.TextTokens>
                        &ensp;&ensp;{tokens}&ensp;&ensp;
                      </s.TextTokens>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementtokens();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.NUMContainer>
 
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
            </s.Container>
          <s.SpacerLarge />
          <s.RightCT>
            <s.DIV2>
              <StyledImg src="/config/images/text.png" />
            </s.DIV2>
          <s.SupplyPrice>
            {CONFIG.Presale === false ? (
              <>
            <s.TextPrice>
            Price: <s.SpacerXSmall/>
            {CONFIG.DISPLAY_COST} ETH
          </s.TextPrice>
              </>
            ) : (
              <>
             <s.TextPrice>
            Price: <s.SpacerXSmall/>
            {CONFIG.WL_Display} ETH
            </s.TextPrice>
              </>
            )}

          </s.SupplyPrice>
          <s.DIV3>
          <StyledImg src="/config/images/trait.png" />
          </s.DIV3>
          </s.RightCT>
        </ResponsiveWrapper>
        <s.SpacerMedium />
      </s.FULLContainer>
    </s.Screen>
  );
}

export default App;
